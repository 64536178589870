import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import { ContractsTableSearchContainer } from './ContractsTableSearchContainer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { contractActions, getFromDate, getToDate } from './redux/contract.redux';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../../configuration';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    locale: string;
    fromDate?: string;
    onFromDateChange: (fromDate?: string) => void;
    toDate?: string;
    onToDateChange: (toDate?: string) => void;
}

export function ContractTableToolBar(props: Props) {
    const { locale, fromDate, onFromDateChange, toDate, onToDateChange } = props;
    return (
        <TableToolbar className={''}>
            <div className='table-toolbar-container'>
                <div className='table-toolbar-group-left'>
                    <div className='table-toolbar-column'>
                        <label>
                            <FormattedMessage id='marketplace.contract.filter.dateFrom' />
                        </label>
                        <DatePicker
                            key={fromDate}
                            inputProps={{
                                readOnly: true,
                                className: 'form-control cursor-pointer bg-white',
                                style: { borderColor: 'rgb(167, 175, 187)' },
                            }}
                            value={fromDate ? new Date(fromDate) : undefined}
                            onChange={(value) => {
                                if (typeof value === 'string') {
                                    if (value === '') {
                                        onFromDateChange(undefined);
                                    }
                                } else {
                                    onFromDateChange(value.format('YYYY-MM-DD'));
                                }
                            }}
                            timeFormat={false}
                            minWidth={160}
                            closeOnSelect
                            clearableInput={true}
                            isValidDate={(currentDate: Date) => (toDate ? currentDate < new Date(toDate) : true)}
                            locale={locale}
                        />
                    </div>
                    <div className='table-toolbar-column'>
                        <label>
                            <FormattedMessage id='marketplace.contract.filter.dateTo' />
                        </label>
                        <DatePicker
                            key={toDate}
                            inputProps={{
                                readOnly: true,
                                className: 'form-control cursor-pointer bg-white',
                                style: { borderColor: 'rgb(167, 175, 187)' },
                            }}
                            value={toDate ? new Date(toDate) : undefined}
                            onChange={(value) => {
                                if (typeof value === 'string') {
                                    if (value === '') {
                                        onToDateChange(undefined);
                                    }
                                } else {
                                    onToDateChange(value.format('YYYY-MM-DD'));
                                }
                            }}
                            timeFormat={false}
                            minWidth={160}
                            closeOnSelect
                            clearableInput={true}
                            isValidDate={(currentDate: Date) => (fromDate ? currentDate > new Date(fromDate) : true)}
                            locale={locale}
                        />
                    </div>
                </div>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <ContractsTableSearchContainer />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
}

const mapStateToProps = (state: RootState) => ({
    locale: getLocale(state),
    fromDate: getFromDate(state),
    toDate: getToDate(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onFromDateChange: (fromDate?: string) => dispatch(contractActions.setFromDate(fromDate)),
    onToDateChange: (toDate?: string) => dispatch(contractActions.setToDate(toDate)),
});

export const ContractTableToolBarContainer = connect(mapStateToProps, mapDispatchToProps)(ContractTableToolBar);
