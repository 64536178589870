import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage, useIntl } from 'react-intl';
import { PreviousButton } from './components/NavigationButtons';
import { Link } from 'react-router-dom';
import { MARKETPLACE_CUSTOMER_CENTER_PATH, MARKETPLACE_HOME_PATH } from '../common/routes';
import { getSelectedProduct, getSelectedProductId } from './redux/checkout.redux';
import { sendError } from '../../../configuration/lang/services';
import { BookingSuccessMessage, SUCCESSFULLY_BOOKED } from '../common/postMessage.types';
import { getServiceLinkForProductId } from '../config/linkAtEndOfCheckout';
import { Product } from './redux/types';
import { getProductLabel } from '../common/utils/productLabelUtil';
import { ProductIcon } from '../common/productImages/ProductIcon';
import { FLEET_PRODUCT_SKU, isFleetProductId } from '../config/fleetProduct';
import { RootState } from '../../../configuration/setup/store';
import { getIsOpenedInPopup } from '../popup/popup.redux';
import { sendIFrameCloseMessageToParent } from '../common/utils/iframeUtils';
import manThumbsUp from '../freeTrial/images/man_thumbs_up.webp';

type GoogleAnalyticsEventAction = 'forwardToService' | 'goToCustomerCenter';

interface Props {
    subscriptionCreationInProgress: boolean;
    hasError: boolean;
    previousPath: string;
    isOpenedInPopup: boolean;
    productId?: string;
    product?: Product;
}

const CheckoutConfirmationPage = (props: Props) => {
    let content;
    const productId = props.productId;
    const productName = props.product ? getProductLabel(props.product, useIntl()) : '';
    const serviceLink = getServiceLinkForProductId(productId);

    const getTrackingAttributes = (action: GoogleAnalyticsEventAction, trigger: 'click' | 'visibility') => ({
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': `${action}_${trigger}`,
        'data-track-ga-event-label': `productId::${productId}`,
    });

    useEffect(() => {
        const success = !props.hasError && !props.subscriptionCreationInProgress;
        if (success && window.opener) {
            try {
                const message: BookingSuccessMessage = { type: SUCCESSFULLY_BOOKED, productId: props.productId };
                window.opener.postMessage(message, window.origin);
            } catch (e) {
                sendError(e);
            }
        }
    }, [props.hasError, props.subscriptionCreationInProgress, props.productId]);

    function close() {
        setTimeout(() => {
            sendIFrameCloseMessageToParent();
            window.close();
        }, 10);
    }

    if (props.hasError) {
        content = (
            <ErrorState
                message={
                    <div className='display-flex flex-column gap-10'>
                        <FormattedMessage id={'marketplace.subscription.create.error'} />
                        <div className='display-flex justify-content-center margin-top-10'>
                            <PreviousButton previousPath={props.previousPath} />
                        </div>
                    </div>
                }
            />
        );
    } else if (props.subscriptionCreationInProgress) {
        content = (
            <Spinner text={<FormattedMessage id={'marketplace.subscription.create.loading'} />} isInverse={false} />
        );
    } else if (isFleetProductId(productId)) {
        content = (
            <CustomState
                image={
                    <ProductIcon
                        productId={FLEET_PRODUCT_SKU}
                        className={'productIcon width-100 height-100 position-relative'}
                    />
                }
                message={
                    <div className='margin-top-20'>
                        <div className='margin-bottom-25'>
                            <FormattedMessage
                                id={'marketplace.checkout.fleetSuccess'}
                                values={{
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    link: (chunks: Array<string>) => (
                                        <a
                                            href={`/${MARKETPLACE_CUSTOMER_CENTER_PATH}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            {...getTrackingAttributes('goToCustomerCenter', 'click')}
                                        >
                                            {chunks}
                                        </a>
                                    ),
                                }}
                                {...getTrackingAttributes('goToCustomerCenter', 'visibility')}
                            />
                        </div>
                        <div className='display-flex justify-content-center margin-top-10'>
                            <button className={'btn btn-primary'} onClick={() => close()}>
                                <FormattedMessage id={'marketplace.checkout.closePopup'} />
                            </button>
                        </div>
                    </div>
                }
            />
        );
    } else if (props.isOpenedInPopup) {
        content = (
            <CustomState
                image={<img className='width-100pct max-width-300' src={manThumbsUp} />}
                message={
                    <div className='margin-top-20'>
                        <h4 className='text-success margin-bottom-50'>
                            <FormattedMessage id={'marketplace.subscription.create.success.singular'} />
                        </h4>
                        <div className='display-flex flex-wrap justify-content-center gap-10 margin-top-20'>
                            {serviceLink && productName && (
                                <span {...getTrackingAttributes('forwardToService', 'visibility')}>
                                    <a
                                        href={serviceLink}
                                        target='_blank'
                                        rel={'noreferrer'}
                                        onClick={() => close()}
                                        {...getTrackingAttributes('forwardToService', 'click')}
                                        className={'btn btn-primary'}
                                    >
                                        <FormattedMessage id={'marketplace.productLink'} values={{ productName }} />
                                    </a>
                                </span>
                            )}

                            <button className={'btn btn-primary'} onClick={() => close()}>
                                <FormattedMessage id={'marketplace.checkout.closePopup'} />
                            </button>
                        </div>
                    </div>
                }
            />
        );
    } else {
        content = (
            <CustomState
                image={<img className='width-100pct max-width-300' src={manThumbsUp} />}
                message={
                    <div className='margin-top-20'>
                        <h4 className='text-success margin-bottom-50'>
                            <FormattedMessage id={'marketplace.subscription.create.success.singular'} />
                        </h4>
                        <div className='margin-top-20'>
                            <FormattedMessage id={'marketplace.subscription.create.success.nextSteps'} />
                        </div>
                        <div className='display-flex flex-wrap justify-content-center gap-10 margin-top-20'>
                            {serviceLink && productName && (
                                <div {...getTrackingAttributes('forwardToService', 'visibility')}>
                                    <a
                                        href={serviceLink}
                                        className={'btn btn-primary'}
                                        {...getTrackingAttributes('forwardToService', 'click')}
                                    >
                                        <FormattedMessage id={'marketplace.goToProduct'} values={{ productName }} />
                                    </a>
                                </div>
                            )}
                            <Link to={`/${MARKETPLACE_HOME_PATH}`} className={'btn btn-default'}>
                                <FormattedMessage id={'marketplace.subscription.goto.products'} />
                            </Link>
                        </div>
                    </div>
                }
            />
        );
    }

    return <div className='Subscription'>{content}</div>;
};

const mapStateToProps = (state: RootState) => ({
    subscriptionCreationInProgress: getIsLoading(state, ApiCalls.SUBSCRIPTION_CREATE),
    hasError: getHasError(state, ApiCalls.SUBSCRIPTION_CREATE),
    isOpenedInPopup: getIsOpenedInPopup(state),
    productId: getSelectedProductId(state),
    product: getSelectedProduct(state),
});

export const CheckoutConfirmationPageContainer = connect(mapStateToProps)(CheckoutConfirmationPage);
