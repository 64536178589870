import { connect } from 'react-redux';
import { StripeCreatePaymentButton } from '../payment/StripeCreatePaymentButton';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { publishPaymentMethodThunk } from '../payment/paymentMethodsPublish.thunk';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

interface Props {
    changePaymentInformation: () => void;
    paymentFetchInProgress: boolean;
}

const SepaCreditTransferPayment = ({ changePaymentInformation, paymentFetchInProgress }: Props) => {
    return (
        <div className='SepaCreditTransferPaymentContainer display-flex justify-content-end'>
            <StripeCreatePaymentButton
                confirmMessageId='marketplace.payment.bankTransfer.update.button'
                loadingMessageId='marketplace.payment.bankTransfer.update.button.inProgress'
                isInProgress={paymentFetchInProgress}
                onClick={changePaymentInformation}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    paymentFetchInProgress: getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    changePaymentInformation: () => dispatch(publishPaymentMethodThunk(PaymentMethodType.SEPA_CREDIT_TRANSFER)),
});

export const SepaCreditTransferPaymentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SepaCreditTransferPayment);
