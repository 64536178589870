const linkAtEndOfCheckout: Record<string, string> = {
    'man-sku00000004': 'https://remotedownload.rio.cloud/#archive',
    'man-sku00000005': 'https://perform.rio.cloud/#fleet',
    'rio-sku00000027': 'https://drivingtime.rio.cloud/#realtime',
    'rio-sku00000047': 'https://livemonitor.rio.cloud/#map',
    'man-sku00000048': 'https://livemonitor.rio.cloud/#map',
    'man-sku00000049': 'https://manservicecare.rio.cloud/',
    'man-sku00000050': 'https://ota-charging.rio.cloud/#overview',
    'man-sku00000061': 'https://ota-charging.rio.cloud/#overview',
    'man-sku00000066': 'https://simplepay.rio.cloud/',
    'man-sku00000081': 'https://jupiter.rio.cloud/',
    'man-sku00000085': 'https://livemonitor.rio.cloud/',
};

export function getServiceLinkForProductId(productId?: string): string | undefined {
    if (!productId) {
        return undefined;
    }
    const lowerCaseProductId = productId.toLowerCase();
    return linkAtEndOfCheckout[lowerCaseProductId];
}
