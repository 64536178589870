import { getPaymentMethods, getPaymentMethodTypes, paymentMethodsActions } from './redux/paymentMethods.redux';
import { fetchPaymentMethods } from '../../api/paymentMethods/paymentInformationCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { Dispatch } from 'redux';
import { fetchPaymentMethodTypes } from '../../api/paymentMethods/paymentMethodTypesCalls';
import { postPaymentMethodPresentIfInIframe } from './paymentMethodsPostMessageService';
import { isBrazilianTenant } from '../utils/tenants';
import { getDefaultPaymentInformation } from '../oldPaymentMethods/redux/paymentMethodsSelectionService';
import { getAccountId } from '../../../../configuration';
import { AppThunk, RootState } from '../../../../configuration/setup/store';

export function fetchPaymentMethodsIfNecessary(dispatch: Dispatch, getState: () => RootState) {
    const state = getState();
    const paymentMethods = getPaymentMethods(state);
    const accountId = getAccountId(getState());
    if (!paymentMethods) {
        dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_GET, true));
        return fetchPaymentMethods(accountId)
            .then((response) => {
                dispatch(paymentMethodsActions.setPaymentMethods(response.methods));
                dispatch(paymentMethodsActions.setPaymentMethodDefaultId(response.defaultId));
                dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_GET, false, false));
            })
            .then(() => {
                if (getDefaultPaymentInformation(getState())) {
                    postPaymentMethodPresentIfInIframe();
                }
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_GET, false, true));
            });
    }
    return Promise.resolve();
}

export function fetchPaymentMethodTypesIfNecessary(dispatch: Dispatch, getState: () => RootState) {
    const state = getState();
    const methodTypes = getPaymentMethodTypes(state);
    const accountId = getAccountId(getState());

    if (!methodTypes) {
        dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_TYPES_GET, true));
        return fetchPaymentMethodTypes(accountId)
            .then((response) => {
                dispatch(paymentMethodsActions.setPaymentMethodTypes(response));
                dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_TYPES_GET, false, false));
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.PAYMENT_METHODS_TYPES_GET, false, true));
            });
    }
    return Promise.resolve();
}

export function fetchPaymentDetailsIfNecessaryByTenant(): AppThunk {
    return (dispatch, getState) => {
        if (!isBrazilianTenant(getState())) {
            dispatch(fetchPaymentMethodsIfNecessary);
            dispatch(fetchPaymentMethodTypesIfNecessary);
        }
    };
}
