import { connect } from 'react-redux';
import { ProductInfo } from '../redux/types';
import { useState } from 'react';
import { IconButtonDefault, IconButtonSecondary, IconButtonSuccess } from './productCellConfig';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { DiscountIdentifier, mapStateToFindDiscountProps } from '../../../common/discount/DiscountService';
import { AssetDiscount } from '../../../common/discount/redux/types';
import { OverlayTrigger } from '../../../common/utils/tooltipReExports';
import { AssetOverviewGotoContractsDialog } from '../AssetOverviewGotoContractsDialog';
import { AssetOverviewGotoFreeTrialDialog } from '../AssetOverviewGotoFreeTrialDialog';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import { ProductCellBookedContractTooltip, ProductCellBookedContractType } from './ProductCellBookedContractTooltip';

interface Props {
    product: ProductInfo;
    assetId: string;
    findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
}

const ProductCellBookedContract = ({ product, assetId, findDiscount }: Props) => {
    const [hover, setHover] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { productId, level } = product;

    const isFreeTrial = isFreeTrialLevel(level);
    const tooltipType = isFreeTrial ? ProductCellBookedContractType.FREE_TRIAL : ProductCellBookedContractType.REGULAR;
    const discount = findDiscount({ assetId, productId, productLevel: level });

    let buttonClasses: string;
    if (discount) {
        buttonClasses = IconButtonSuccess;
    } else if (isFreeTrial) {
        buttonClasses = IconButtonSecondary;
    } else {
        buttonClasses = IconButtonDefault;
    }

    return (
        <span>
            {showDialog && isFreeTrial && <AssetOverviewGotoFreeTrialDialog closeDialog={() => setShowDialog(false)} />}
            {showDialog && !isFreeTrial && (
                <AssetOverviewGotoContractsDialog closeDialog={() => setShowDialog(false)} />
            )}
            <OverlayTrigger
                trigger={OverlayTrigger.TRIGGER_HOVER}
                placement='top'
                overlay={<ProductCellBookedContractTooltip type={tooltipType} discount={discount} />}
                delay={DEFAULT_TOOLTIP_DELAY}
            >
                <span>
                    <button
                        className={buttonClasses}
                        onClick={() => setShowDialog(true)}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <span className={`rioglyph ${hover ? 'rioglyph-lock' : 'rioglyph-ok'} margin-0`} />
                    </button>
                </span>
            </OverlayTrigger>
        </span>
    );
};

export const ProductCellBookedContractContainer = connect(mapStateToFindDiscountProps)(ProductCellBookedContract);
