import { getRequest } from '../requests';
import { decodeJson, ignoreError, jsonOrReject } from '../api';
import { ApiAssetInfoBodyCodec, ApiTagsCodec } from './tag.types';
import { mapAssetInfo, mapTags } from './tagMapper';
import { Tag, TagToAssetsMap } from '../../customerCenter/assetOverview/redux/types';
import { getApiAssets, getApiTags } from '../../config/pathURL/config';

export function fetchTags(): Promise<Array<Tag>> {
    return fetch(`${getApiTags()}/tags`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiTagsCodec))
        .then(mapTags)
        .catch((error) => ignoreError(error, []));
}

export function fetchAssetInfo(): Promise<TagToAssetsMap> {
    return fetch(`${getApiAssets()}/assets?embed=(tags)`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiAssetInfoBodyCodec))
        .then(mapAssetInfo)
        .catch((error) => ignoreError(error, {}));
}
