/* eslint-disable camelcase */

import { DisplayMessages } from '../../../../configuration';
import { ApiMissingFeature } from './bookable.types';

function getTranslatedFeatureName(featureName: string, displayMessages: DisplayMessages) {
    switch (featureName) {
        case 'brand':
            return displayMessages['marketplace.missingFeature.brand'];
        case 'connection':
            return displayMessages['marketplace.missingFeature.connection'];
        case 'gateway':
            return displayMessages['marketplace.missingFeature.gateway'];
        case 'type':
            return displayMessages['marketplace.missingFeature.type'];
        case 'tenant':
            return displayMessages['marketplace.missingFeature.tenant'];
        case 'fuelType':
            return displayMessages['marketplace.missingFeature.fuelType'];
        default:
            return featureName;
    }
}

const translateIfNeeded = (displayMessages: DisplayMessages) => (value: string) => {
    switch (value.toLowerCase()) {
        case 'bus':
            return displayMessages['marketplace.vehicleType.bus'];
        case 'truck':
            return displayMessages['marketplace.vehicleType.truck'];
        case 'van':
            return displayMessages['marketplace.vehicleType.van'];
        case 'trailer':
            return displayMessages['marketplace.vehicleType.trailer'];
        default:
            return value;
    }
};

export function translateMissingFeature(
    apiMissingFeature: ApiMissingFeature,
    displayMessages: DisplayMessages
): string {
    const { actual, expected_values, feature_name, inclusive } = apiMissingFeature;
    if (feature_name === 'tacho') {
        return expected_values && expected_values[0] === 'true'
            ? displayMessages['marketplace.onlyWithTacho']
            : displayMessages['marketplace.onlyWithoutTacho'];
    }
    const actualValue = actual
        ? translateIfNeeded(displayMessages)(actual)?.toUpperCase()
        : `"${displayMessages['marketplace.unknown']}"`;

    const expectedValues = expected_values
        .map(translateIfNeeded(displayMessages))
        .map((it) => it.toUpperCase())
        .join(` ${displayMessages['marketplace.or']} `);

    const displayMessage = (
        inclusive
            ? displayMessages['marketplace.missingFeature.inclusive']
            : displayMessages['marketplace.missingFeature.exclusive']
    )
        .replace('{feature}', getTranslatedFeatureName(feature_name, displayMessages))
        .replace('{actualValue}', actualValue)
        .replace('{expectedValues}', expectedValues);

    if (feature_name === 'fuelType') {
        return addHint(displayMessage, displayMessages['marketplace.missingFeature.fuelType.hint']);
    }

    return displayMessage;
}

function addHint(message: string, hint: string) {
    return message.concat(`. ${hint}`);
}
