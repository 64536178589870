type Typo3Mapping = {
    partner: string;
    product: string;
    iconName: string | undefined;
    termsAndConditions?: string | undefined;
    newTermsAndConditions?: string | undefined;
};

/* For a multi level product for the icon, we need an entry with the plain product id */

const TYPO3_PRODUCT_NAME_MAPPING: Record<string, Typo3Mapping> = {
    'man-sku00000004': {
        partner: 'MAN',
        product: 'Compliant',
        iconName: 'Compliant',
        newTermsAndConditions: 'compliant/tos',
    },
    'man-sku00000005': {
        partner: 'MAN',
        product: 'Perform',
        iconName: 'Perform',
        newTermsAndConditions: 'perform/tos',
    },
    'rio-sku00000027': { partner: 'RIO', product: 'Timed', iconName: 'Timed', newTermsAndConditions: 'timed/tos' },
    'rio-sku00000047-34': { partner: 'RIO', product: 'Geo', iconName: 'Geo', newTermsAndConditions: 'geo/tos/l' },
    'rio-sku00000047': { partner: 'RIO', product: 'Geo', iconName: 'Geo', newTermsAndConditions: 'geo/tos' },
    'rio-sku00000055': {
        partner: 'RIO',
        product: 'Geo_Data_Flow',
        iconName: 'Geo_Data_Flow',
        newTermsAndConditions: 'geo-data-flow/tos',
    },
    'rio-sku00000056': { partner: 'RIO', product: 'Timed_Data_Flow', iconName: 'Timed_Data_Flow' },
    sku00000010: { partner: 'MAN', product: 'MAN_Bridge', iconName: undefined },
    'man-sku00000050': {
        partner: 'MAN',
        product: 'MAN_eManager',
        iconName: 'MAN_eManager',
        newTermsAndConditions: 'man-emanager/tos/s-m',
    },
    'man-sku00000003': { partner: 'MAN', product: 'MAN_Maintenance', iconName: 'Maintenance' },
    'rio-sku00000003': { partner: 'RIO', product: 'RIO_Maintenance', iconName: 'Maintenance' },
    'man-sku00000026': { partner: 'MAN', product: 'MAN_Now', iconName: 'MAN_Now' },
    sku00000002: { partner: 'MAN', product: 'MAN_ServiceCare', iconName: 'MAN_ServiceCare' },
    'man-sku00000049': {
        partner: 'MAN',
        product: 'MAN_ServiceCare',
        iconName: 'MAN_ServiceCare',
        newTermsAndConditions: 'man-serviecare/tos/s-m',
    },
    'man-sku00000048': { partner: 'MAN', product: 'One_Minute_Locator', iconName: 'One_Minute_Locator' },
    'man-sku00000052': { partner: 'MAN', product: 'MapUpdate', iconName: 'MAN-Now-MapUpdate' },
    'man-sku00000060': { partner: 'MAN', product: 'OnlineTraffic', iconName: 'MAN-Now-OnlineTraffic' },
    'man-sku00000061': {
        partner: 'MAN',
        product: 'MinimalDataSet',
        iconName: 'MinimalDataSet',
        newTermsAndConditions: 'minimaldatenset/tos',
    },
    'man-sku00000066': {
        partner: 'MAN',
        product: 'MAN_SimplePay',
        iconName: 'Jupiter',
        newTermsAndConditions: 'man-simplepay/tos',
    },
    'man-sku00000072': { partner: 'MAN', product: 'EfficientRoute', iconName: 'MAN-Now-EfficientRoute' },
    'man-sku00000076': { partner: 'MAN', product: 'OnlineNews', iconName: 'MAN-Now-OnlineNews' },
    'wabco-sku00000030': { partner: 'RIO', product: '4TX_Trailerpulse', iconName: '4TX_Trailerpulse' },
    'kor-sku00000053': { partner: 'MAN', product: 'MAN_ServiceCare_S', iconName: 'MAN_ServiceCare' },
    'kor-sku00000054': { partner: 'MAN', product: 'Perform', iconName: 'Perform' },
    'rio-sku00000045': {
        partner: 'RIO',
        product: 'Dispatch',
        termsAndConditions: 'Dispatch_BETA',
        iconName: 'Dispatch',
    },
    'rio-sku00000058': { partner: 'RIO', product: 'RIO_Maintenance', iconName: 'Maintenance' },
    'rio-sku00000059': { partner: 'RIO', product: 'RIO_Maintenance', iconName: 'Maintenance' },
    'rio-sku00000065': { partner: 'RIO', product: 'Fleet', iconName: 'Fleet' },
    'rio-sku00000070': { partner: 'RIO', product: 'Order', iconName: 'TransportManagementSystem' },
    'rio-sku00000071': { partner: 'RIO', product: 'User Test Product', iconName: 'Staff' },
    'man-sku00000073': {
        partner: 'MAN',
        product: 'MAN_DataPackage',
        iconName: 'DataProvision',
        newTermsAndConditions: 'man-datapackage/tos/s-tigr',
    },
    'latam-sku00000075': { partner: 'LATAM', product: 'LATAM Test Product', iconName: undefined },
    'latam-sku00000078': { partner: 'LATAM', product: 'RIO Premium', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000079': { partner: 'LATAM', product: 'RIO E-Fleet', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000080': { partner: 'LATAM', product: 'RIO Basics', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000088': { partner: 'LATAM', product: 'RIO Basics + VolksTotal', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000089': { partner: 'LATAM', product: 'RIO Premium + VolksTotal', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000090': { partner: 'LATAM', product: 'RIO E-Fleet + VolksTotal', iconName: undefined }, // TODO fix icon and T&C URL
    'latam-sku00000095': { partner: 'LATAM', product: 'Volks|Blocker', iconName: undefined }, // TODO fix icon and T&C URL
    'man-sku00000081': { partner: 'MAN', product: '', iconName: 'Jupiter' }, // TODO product for AGB
    'man-sku00000085': { partner: 'MAN', product: 'MAN SmartRoute', iconName: 'SmartRoutePlanning' }, // TODO T&C
};

export function getTypo3ProductInfo(productIdentifier: string): Typo3Mapping | undefined {
    const mappingByFullIdentifier = TYPO3_PRODUCT_NAME_MAPPING[productIdentifier.toLowerCase()];
    if (mappingByFullIdentifier) {
        return mappingByFullIdentifier;
    }
    // Strip level suffix if present
    if (productIdentifier.lastIndexOf('-') > 0) {
        const productIdWithoutLevel = productIdentifier.toLowerCase().substring(0, productIdentifier.lastIndexOf('-'));
        return TYPO3_PRODUCT_NAME_MAPPING[productIdWithoutLevel];
    }
    return undefined;
}

export function getTypo3IconName(productIdentifier: string): string | undefined {
    return getTypo3ProductInfo(productIdentifier)?.iconName;
}
