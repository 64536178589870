import { createSelector } from 'reselect';
import { getPaymentMethodDefaultId, getPaymentMethodEditMode, getPaymentMethods } from '../../payment/redux/paymentMethods.redux';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';

export const getDefaultPaymentInformation = createSelector(
    getPaymentMethods,
    getPaymentMethodDefaultId,
    (methods, methodDefaultId) => {
        if (methods && methods.length) {
            return methods.find((paymentMethod) => paymentMethod.id === methodDefaultId);
        }
    }
);

export const getPaymentDetailsIsFetched = (state) => {
    return !(
        getPaymentMethodEditMode(state) ||
        !getDefaultPaymentInformation(state) ||
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET)
    );
};

export const getPaymentDetailsIsLoading = (state) => {
    return (
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
        getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET)
    );
};
