import { PaymentMethod } from '../payment/redux/types';
import { safeDataLayerPush } from '../utils/googleTagManagerWrapper';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';

export type GAPaymentType = 'sepa_debit' | 'card' | 'sepa_credit_transfer' | 'bank_transfer';

export function getGAPaymentType(paymentMethodType: PaymentMethodType): GAPaymentType {
    switch (paymentMethodType) {
        case PaymentMethodType.SEPA:
            return 'sepa_debit';
        case PaymentMethodType.CREDIT_CARD:
            return 'card';
        case PaymentMethodType.SEPA_CREDIT_TRANSFER:
            return 'sepa_credit_transfer';
        case PaymentMethodType.BANK_TRANSFER:
            return 'bank_transfer';
        default:
            throw new UnreachableCaseError(paymentMethodType);
    }
}

export function publishPaymentMethodUpdateToGA(
    previousInformation: PaymentMethod | undefined,
    newPaymentType: GAPaymentType
) {
    if (previousInformation && previousInformation.paymentType) {
        const paymentType = previousInformation.paymentType;
        safeDataLayerPush(() => ({
            event: 'PaymentMethodChange',
            eventPayload: {
                paymentType: newPaymentType,
                previousPaymentType: getGAPaymentType(paymentType),
            },
        }));
    } else {
        safeDataLayerPush(() => ({
            event: 'PaymentMethodAdded',
            eventPayload: {
                paymentType: newPaymentType,
            },
        }));
    }
}
