export const LOCALE_PLACEHOLDER = 'LOCALE';

export function setLocaleForRedirect(url: string, locale: string) {
    return url.replace(LOCALE_PLACEHOLDER, locale.substr(0, 2));
}

export const OPENMARKETPLACE_BASE_URL = `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/`;
export const KOREA_OPENMARKETPLACE_BASE_URL = `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/services`;
export const LATAM_OPENMARKETPLACE_BASE_URL = 'https://americas.rio.cloud/marketplace/';

export const REDIRECT_CONFIG = [
    { sku: 'RIO-SKU00000051', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-30', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-40', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-30', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000039', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/rio-box` },
    { sku: 'RIO-SKU00000042', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/mixed-fleet-bridge` },
    { sku: 'SKU00000010', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-bridge` },
    { sku: 'RIO-SKU00000047', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'SKU00000006', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'MAN-SKU00000048', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/one-minute-locator` },
    { sku: 'SIXFOLD-SKU00000041', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51709` },
    { sku: 'MAN-SKU00000005', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'RIO-SKU00000005', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'RIO-SKU00000027', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000055', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo-data-flow` },
    { sku: 'RIO-SKU00000056', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed-data-flow` },
    {
        sku: 'MAN-SKU00000015',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-connected-codriver`,
    },
    { sku: 'RIO-SKU00000045', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/dispatch` },
    { sku: 'SOLOPLAN-SKU00000036', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51736` },
    { sku: 'HEUREMO-SKU00000033', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51744` },
    { sku: 'LIS-SKU00000037', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51741` },
    { sku: 'MAN-SKU00000004', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-31', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000004-32', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'RIO-SKU00000004', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'DAKO-SKU00000032', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51759` },
    { sku: 'TACHOGRAF-SKU00000034', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51747` },
    { sku: 'TRANSICS-SKU00000018', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51750` },
    { sku: 'VDO-SKU00000016', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51753` },
    { sku: 'TRACKS-SKU00000044', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51756` },
    {
        sku: 'WABCO-SKU00000030',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner/tx-trailerpulse-connect`,
    },
    { sku: 'MAN-SKU00000049-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'RIO-SKU00000003', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000003', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-s` },
    { sku: 'MAN-SKU00000050-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-s` },
    { sku: 'MAN-SKU00000050-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000050-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000050-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'SKU00000002', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-s` },
    { sku: 'MAN-SKU00000061', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'RIO-DKV-SKU00000031', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/dkv-card` },
    { sku: 'MAN-SKU00000007', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/legal#c70094` },
    { sku: 'RIO-SKU00000007', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/legal#c70089` },
    {
        sku: 'KOR-SKU00000053',
        url: `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/product/man-servicecare-s`,
        korea: true,
    },
    {
        sku: 'KOR-SKU00000054',
        url: `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/product/perform`,
        korea: true,
    },
    { sku: 'MAN-SKU00000026', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-now` },
    // TODO MANWS-5419 adapt when Minimaldatenset is in the open marketplace
    { sku: 'MAN-SKU00000061', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager` },
    // TODO Add page in Openmarketplace for Jupiter
    { sku: 'MAN-SKU00000066', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager` },
    { sku: 'MAN-SKU00000073-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-datapackage-s` },
    {
        sku: 'MAN-SKU00000073-20',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-datapackage-tigr`,
    },
    {
        sku: 'MAN-SKU00000085',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-smartroute`,
    },
    { sku: 'LATAM-SKU00000078', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000079', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000080', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000088', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000089', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000090', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000095', url: LATAM_OPENMARKETPLACE_BASE_URL },
];

export const PRODUCT_DETAILS_REDIRECT = [
    { category: 'a00000001', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/overview` },
    { category: 'a00000002', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/man-digital-services` },
    { category: 'a00000003', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner` },
];
