/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

declare const SERVICE_VERSION: string;
declare const SERVICE_ENVIRONMENT: string;

if (config.sentryToken) {
    // version and environment are defined in the webpack.define plugin
    const release = SERVICE_VERSION;
    const environment = SERVICE_ENVIRONMENT;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        ignoreErrors: [
            'TypeError: Failed to fetch',
            'TypeError: Load failed',
            'TypeError: Cancelled',
            'TypeError: cancelled',
            'TypeError: cancelado',
            'TypeError: Abgebrochen',
            'TypeError: Zeitüberschreitung bei der Anforderung.',
            'TypeError: anulowane',
            'TypeError: annullato',
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: visszavonva',
            'TypeError: отменено',
            'TypeError: annulé',
            'TypeError: geannuleerd',
            'Non-Error promise rejection captured',
            'No state in response',
            'Error: Frame window timed out',
            'Error: Network Error',
            'Error: expected accountId to be present but it was not',
        ],
        environment,
        release,
    });
}

export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
