import { Tooltip } from '../../../common/utils/tooltipReExports';
import { FormattedMessage, useIntl } from 'react-intl';
import { generateDiscountHint } from '../../../common/discount/DiscountService';
import { AssetDiscount } from '../../../common/discount/redux/types';

export enum ProductCellBookedContractType {
    FREE_TRIAL,
    REGULAR,
}

interface ProductCellBookedContractTooltipProps {
    type: ProductCellBookedContractType;
    discount: AssetDiscount | undefined;
}

export function ProductCellBookedContractTooltip(props: ProductCellBookedContractTooltipProps) {
    const { type, discount } = props;
    const intl = useIntl();
    const toolTipId =
        type === ProductCellBookedContractType.FREE_TRIAL
            ? 'marketplace.freeTrial.gotoTab'
            : 'marketplace.myServices.manageContracts';

    return (
        <Tooltip id='tooltip' className={'width-auto max-width-300'}>
            <div>
                <FormattedMessage id={toolTipId} />
            </div>
            {discount && (
                <div>
                    <b>{generateDiscountHint(discount, intl)}</b>
                </div>
            )}
        </Tooltip>
    );
}
