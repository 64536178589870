import { ProductState } from '../../checkout/resourceSelection/redux/types';

export enum PeriodUnit {
    YEAR = 'years',
    MONTH = 'months',
    DAY = 'days',
}

export interface Period {
    length: number;
    unit: PeriodUnit;
}

export enum TermedLevelState {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
}

export enum HumanReadableTermedLevelState {
    'active' = 'active',
    'active_terminating_soon' = 'active_terminating_soon',
    'pending' = 'pending',
    'active_without_activation' = 'active_without_activation',
}

export interface TermedLevel {
    resourceId: string;
    resourceName?: string;
    resourceType: string;
    productId: string;
    productName: string;
    productLevelName: string;
    productLevelIsFree?: boolean;
    level: string;
    state: TermedLevelState;
    activationState: ProductState;
    periodLength: number;
    periodUnit: PeriodUnit;
    startsAt?: string;
    endsAt?: string;
    renewsToLevel?: string;
    renewsToLevelName?: string;
    renewsToLevelVariantName?: string;
    renewsToLevelContractPeriodLength?: number;
    renewsToLevelContractPeriodUnit?: PeriodUnit;
    remainingDays?: number;
    criticalRemainingDays: boolean;
    contractState: HumanReadableTermedLevelState;
    hasInactiveActivationState: boolean;
    missingRequirements?: Array<Array<string>>;
    variantName: string;
}
