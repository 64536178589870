import { useEffect } from 'react';
import { connect } from 'react-redux';
import { PaymentMethodPage } from './PaymentMethodPage';
import {
    getPaymentMethodEditMode,
    getPaymentMethods,
    getPaymentMethodTypes,
    paymentMethodsActions,
} from '../payment/redux/paymentMethods.redux';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import LoadingErrorStateWithReload from '../error/LoadingErrorStateWithReload';
import { fetchPaymentDetailsIfNecessaryByTenant } from '../payment/paymentMethods.thunk';
import { PaymentMethod } from '../payment/redux/types';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { getDefaultPaymentInformation } from './redux/paymentMethodsSelectionService';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { isBrazilianTenant } from '../utils/tenants';
import { FormattedMessage } from 'react-intl';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { getAccountId } from '../../../../configuration';
import { LOCAL_MOCK_ACCOUNT_ID, PAYMENT_SANDBOX_ACCOUNT_ID } from './StripePaymentFormContainer';

interface Props {
    defaultPaymentInformation?: PaymentMethod;
    isLoading: boolean;
    hasError: boolean;
    paymentMethodTypes?: PaymentMethodType[];
    fetchPaymentDetailsIfNecessary: () => void;
    setEditMode: (isEditMode: boolean) => void;
    isEditPayment: boolean;
    isAllowedTenant: boolean;
    accountId: string;
}

function PaymentMethodPageEurope(props: Props) {
    const {
        defaultPaymentInformation,
        isLoading,
        hasError,
        paymentMethodTypes,
        fetchPaymentDetailsIfNecessary,
        setEditMode,
        isEditPayment,
        isAllowedTenant,
        accountId,
    } = props;
    useEffect(() => {
        fetchPaymentDetailsIfNecessary();
    }, [fetchPaymentDetailsIfNecessary]);

    if (hasError) {
        return (
            <LoadingErrorStateWithReload
                onReload={() => fetchPaymentDetailsIfNecessary()}
                headline={'marketplace.payment.information.error'}
            />
        );
    }

    if (!isAllowedTenant) {
        return <ErrorState headline={<FormattedMessage id={'marketplace.unauthorized.dialog.message'}/>}/>;
    }

    if (isLoading || !paymentMethodTypes) {
        return (
            <div className='padding-20'>
                <Spinner text={''} isInverse={false}/>
            </div>
        );
    }

    const filteredPaymentMethodTypes =
        (accountId === PAYMENT_SANDBOX_ACCOUNT_ID || accountId === LOCAL_MOCK_ACCOUNT_ID) ?
            paymentMethodTypes.filter(i => i !== PaymentMethodType.SEPA_CREDIT_TRANSFER)
            : paymentMethodTypes.filter(i => i !== PaymentMethodType.BANK_TRANSFER);

    return (
        <PaymentMethodPage
            paymentInformation={defaultPaymentInformation}
            paymentMethodTypes={filteredPaymentMethodTypes}
            setEditMode={setEditMode}
            isEditPayment={isEditPayment}
        />
    );
}

function mapStateToProps(state: RootState) {
    return {
        defaultPaymentInformation: getDefaultPaymentInformation(state),
        hasError:
            getHasError(state, ApiCalls.PAYMENT_METHODS_GET) ||
            getHasError(state, ApiCalls.PAYMENT_METHODS_TYPES_GET) ||
            getHasError(state, ApiCalls.PAYMENT_METHODS_POST),
        isLoading:
            !getPaymentMethods(state) ||
            !getPaymentMethodTypes(state) ||
            getIsLoading(state, ApiCalls.PAYMENT_METHODS_GET) ||
            getIsLoading(state, ApiCalls.PAYMENT_METHODS_POST) ||
            getIsLoading(state, ApiCalls.PAYMENT_METHODS_TYPES_GET),
        paymentMethodTypes: getPaymentMethodTypes(state),
        isEditPayment: getPaymentMethodEditMode(state),
        isAllowedTenant: !isBrazilianTenant(state),
        accountId: getAccountId(state),
    };
}

function mapDispatchToProps(dispatch: RootDispatch) {
    return {
        fetchPaymentDetailsIfNecessary: () => {
            const returnedAction = fetchPaymentDetailsIfNecessaryByTenant();
            if (returnedAction) {
                dispatch(returnedAction);
            }
        },
        setEditMode: (isEditMode: boolean) => dispatch(paymentMethodsActions.setPaymentMethodEditMode(isEditMode)),
    };
}

export const PaymentMethodPageContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodPageEurope);
