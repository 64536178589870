import { FormattedMessage } from 'react-intl';
import { PaymentInformation } from '../../common/oldPaymentMethods/PaymentInformation';
import { connect } from 'react-redux';
import { isPurchaseFree } from '../CheckoutService';
import { PaymentMethod } from '../../common/payment/redux/types';
import { getDefaultPaymentInformation } from '../../common/oldPaymentMethods/redux/paymentMethodsSelectionService';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    isPurchaseFree: boolean;
    paymentInformation?: PaymentMethod;
}

const PaymentInformationPreview = (props: Props) => {
    if (props.isPurchaseFree) {
        return (
            <div className='noPaymentInfo'>
                <FormattedMessage id='marketplace.payment.information.free' />
            </div>
        );
    }
    return <PaymentInformation paymentInformation={props.paymentInformation} />;
};

const mapStateToProps = (state: RootState) => ({
    isPurchaseFree: isPurchaseFree(state),
    paymentInformation: getDefaultPaymentInformation(state),
});

export const PaymentInformationPreviewContainer = connect(mapStateToProps)(PaymentInformationPreview);
