import { connect } from 'react-redux';
import { useEffect } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { fetchRenewalDialogDataThunk, isDialogDataLoaded } from './renewData.thunk';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { RenewDialogFooterContainer } from './RenewFooterContainer';
import { RenewDialogDataTabsContainer } from './RenewDialogDataTabs';
import { RenewDialogContentManNowContainer } from './RenewDialogContentManNow';
import { isManNowProduct } from '../../../config/manNowProducts';
import { FormattedMessage } from 'react-intl';
import { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import {
    getIsRenewDialogOpen,
    getProductForUpdate,
    getRenewsToLevelForUpdate,
} from '../redux/termedLevelRenewal.redux';
import { TermedProductInfo } from '../redux/types';
import { getTermedLevelsForUpdate } from './dialogService';
import { getDefaultPaymentInformation } from '../../oldPaymentMethods/redux/paymentMethodsSelectionService';
import { RenewDialogPaymentMethodContent } from './RenewDialogPaymentMethodContent';
import { isBrazilianTenant } from '../../utils/tenants';

interface Props {
    product?: TermedProductInfo;
    isDialogOpen: boolean;
    isOnlyOneContractSelected: boolean;
    isLoading: boolean;
    fetchData: () => void;
    isShowPaymentMethod: boolean;
}

export const RenewDialogContent = (props: Props) => {
    const { product, fetchData, isDialogOpen, isLoading, isOnlyOneContractSelected, isShowPaymentMethod } = props;
    const textId = isOnlyOneContractSelected
        ? 'marketplace.contracts.renewal.dialog.text.singular'
        : 'marketplace.contracts.renewal.dialog.text.plural';

    useEffect(() => {
        fetchData();
    }, []);

    if (!isDialogOpen) {
        return <div />;
    }

    if (isLoading || !product) {
        return <Spinner />;
    }

    if (isManNowProduct(product.productId)) {
        return (
            <div className={'margin-bottom-20'}>
                <RenewDialogContentManNowContainer />
            </div>
        );
    }

    return (
        <div className={'margin-bottom-20'}>
            <div className={'margin-bottom-20'}>
                <FormattedMessage id={textId} />
            </div>
            <RenewDialogDataTabsContainer
                paymentMethodContainer={isShowPaymentMethod ? <RenewDialogPaymentMethodContent /> : undefined}
            />
            {!isShowPaymentMethod && <RenewDialogFooterContainer />}
        </div>
    );
};

export const requirePaymentMethod = (state: RootState, product: TermedProductInfo | undefined): boolean => {
    const selectedLevel = getRenewsToLevelForUpdate(state);
    const productLevelPrice = product?.levels?.find((level) => level.level === selectedLevel)?.price ?? 0;

    return !getDefaultPaymentInformation(state) && productLevelPrice > 0 && !isBrazilianTenant(state);
};

const mapStateToProps = (state: RootState) => {
    const { updatable, notUpdatable } = getTermedLevelsForUpdate(state);
    const product = getProductForUpdate(state);
    return {
        product: product,
        isDialogOpen: getIsRenewDialogOpen(state),
        isOnlyOneContractSelected: [...updatable, ...notUpdatable].length === 1,
        isLoading:
            !isDialogDataLoaded(state) ||
            getIsLoading(state, ApiCalls.CONTRACT_BOOKABLE) ||
            getIsLoading(state, ApiCalls.CONTRACT_PRODUCT),
        isShowPaymentMethod: requirePaymentMethod(state, product),
    };
};
const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchData: () => dispatch(fetchRenewalDialogDataThunk),
});

export const RenewDialogContentContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialogContent);
