import { fetchBookableResources } from '../../../api/bookable/bookableCalls';
import { getDisplayMessages } from '../../../../../configuration';
import { BookableResource } from '../../../checkout/resourceSelection/redux/types';
import { apiCallAction } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { sendError } from '../../../../../configuration/lang/services';
import { fetchContractProductDetails } from '../../../api/product/productCalls';
import { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import {
    getMissingRequirementsByAssetForUpdate,
    getProductForUpdate,
    getProductIdForUpdateOrThrow,
    getRenewsToLevelForUpdate,
    termedLevelRenewalActions,
} from '../redux/termedLevelRenewal.redux';
import { MissingRequirementsByAssets } from '../redux/types';
import { fetchPaymentDetailsIfNecessaryByTenant } from '../../payment/paymentMethods.thunk';
import { getPaymentDetailsIsLoading } from '../../oldPaymentMethods/redux/paymentMethodsSelectionService';

function mapToMissingRequirementsByAsset(bookableAssets: Array<BookableResource>) {
    return bookableAssets.reduce((result: MissingRequirementsByAssets, item) => {
        result[item.id] = item.missingRequirements;
        return result;
    }, {});
}

export const fetchMissingRequirementsThunk =
    (productId: string, level: string) => (dispatch: RootDispatch, getState: () => RootState) => {
        const displayMessages = getDisplayMessages(getState());

        dispatch(apiCallAction(ApiCalls.CONTRACT_BOOKABLE, true, false));
        return fetchBookableResources(productId, level, displayMessages)
            .then((bookableAssets) => {
                const missingRequirementsByAsset = mapToMissingRequirementsByAsset(bookableAssets);
                dispatch(termedLevelRenewalActions.setMissingRequirementsForUpdate(missingRequirementsByAsset));
                dispatch(apiCallAction(ApiCalls.CONTRACT_BOOKABLE, false, false));
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.CONTRACT_BOOKABLE, false, true));
            });
    };

export const fetchContractProductThunk = (productId: string) => async (dispatch: RootDispatch) => {
    dispatch(apiCallAction(ApiCalls.CONTRACT_PRODUCT, true));

    return await fetchContractProductDetails(productId)
        .then((product) => {
            dispatch(termedLevelRenewalActions.setProductForUpdate(product));
            dispatch(apiCallAction(ApiCalls.CONTRACT_PRODUCT, false, false));
        })
        .catch((error) => {
            dispatch(apiCallAction(ApiCalls.CONTRACT_PRODUCT, false, true));
            sendError(error);
        });
};

export const fetchRenewalDialogDataThunk = async (dispatch: RootDispatch, getState: () => RootState) => {
    const productId = getProductIdForUpdateOrThrow(getState());
    const level = getRenewsToLevelForUpdate(getState());

    dispatch(termedLevelRenewalActions.setProductForUpdate(undefined));
    dispatch(termedLevelRenewalActions.setMissingRequirementsForUpdate(undefined));

    await Promise.all([
        dispatch(fetchLevelRenewalData(productId, level)),
        dispatch(fetchContractProductThunk(productId)),
        dispatch(fetchPaymentDetailsIfNecessaryByTenant()),
    ]);
};

export const selectRenewalDialogTabThunk =
    (level?: string) => async (dispatch: RootDispatch, getState: () => RootState) => {
        const productId = getProductIdForUpdateOrThrow(getState());

        dispatch(termedLevelRenewalActions.setRenewsToLevelForUpdate(level));
        dispatch(termedLevelRenewalActions.setMissingRequirementsForUpdate(undefined));

        await dispatch(fetchLevelRenewalData(productId, level));
    };

const fetchLevelRenewalData = (productId: string, level?: string) => async (dispatch: RootDispatch) => {
    if (level) {
        await dispatch(fetchMissingRequirementsThunk(productId, level));
    }
};

export const isDialogDataLoaded = (state: RootState): boolean => {
    const level = getRenewsToLevelForUpdate(state);
    const product = getProductForUpdate(state);
    const paymentMethods = getPaymentDetailsIsLoading(state);
    if (!level) {
        return !!product && !paymentMethods;
    }
    const missingRequirements = getMissingRequirementsByAssetForUpdate(state);
    return !!product && !!missingRequirements && !paymentMethods;
};
